// Auto-generated RPC interface from /opt/whdata/installedmodules/only_m.20230707T082907.114Z/webdesigns/only_m/pages/clubfinder/clubfinder.rpc.json
var RPCClient = require("@mod-system/js/wh/rpc").default;
var request = exports.rpcclient = new RPCClient("only_m:clubfindermap");
exports.rpcResolve = function(promise, result) { request._handleLegacyRPCResolve(promise, result) };
exports.invoke = function() { return request.invoke.apply(request,Array.prototype.slice.call(arguments)); }

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function() { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function() { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function() { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function() { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function() { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function() { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function() { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/whdata/installedmodules/only_m.20230707T082907.114Z/webdesigns/only_m/pages/clubfinder/clubfinder.whlib'

exports.findClubs = exports.FindClubs = /*RECORD*/function(/*STRING*/ query)
{
return request.invoke.apply(request,["FindClubs"].concat(Array.prototype.slice.call(arguments)));
}

exports.searchClubsWithinBounds = exports.SearchClubsWithinBounds = /*RECORD ARRAY*/function(/*RECORD*/ sw, /*RECORD*/ ne, /*INTEGER*/ zoomlevel, /*INTEGER*/ maxresults, /*RECORD*/ referencelatlng)
{
return request.invoke.apply(request,["SearchClubsWithinBounds"].concat(Array.prototype.slice.call(arguments)));
}
