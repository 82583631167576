/* global $ */

import * as dompack from 'dompack';
import '@mod-nubshop/webdesigns/nubshop/nubshop';
import Clubfinder from './pages/clubfinder/clubfinder.es';

import './only_m.scss';
import 'sweetalert2/dist/sweetalert2.min.css';

import * as wrdauth from '@mod-wrd/js/auth';

dompack.onDomReady(() => {

  const clubfinderMap = dompack.qS('#clubfinder-map');

  if(clubfinderMap) {
    new Clubfinder(clubfinderMap, { resultsContainer: dompack.qS('#clubfinder-results')});
  }

  if (document.documentElement.classList.contains('page-nubshop--category')) {
    imageHover();
  }
  let html = dompack.qS("html.page-nubshop--category");

  html.addEventListener("getdeffered", (e) => { 
    imageHover();
  });
});

// Takes care of image change on hover.
function imageHover() {
  let $product = $('.ns-product__container'); // Container with all the products.

  $product.each(function(){
    let $this = $(this); // Product element
    let $imageContainer = $($this.find('.ns-product__image-container')); // Container within the product element.

    if($this.data('images') == undefined)
      return;

    if ($imageContainer.find('.ns-product__image').length > 1)
      return;

    if ($this.data('images').length > 1) {
      $imageContainer.prepend(`<div class="ns-product__image" style='background: ${$this.data('images')[1].image.css}'></div>`);
      $imageContainer.mouseover(function(){
        $($imageContainer.find('.ns-product__image')[1]).css("left", $imageContainer.width());
        $($imageContainer.find('.ns-product__image')[1]).css("right", -$imageContainer.width());
      });
      $imageContainer.mouseout(function(){
        $($imageContainer.find('.ns-product__image')[1]).css("left", 0);
        $($imageContainer.find('.ns-product__image')[1]).css("right", 0);
      });
    }
  });
}
