import './nubshop.scss';
// import * as dompack from 'dompack';
import Nubshop from '@mod-nubshop/web/pages/webshop/webshop';

// dompack.onDomReady(() => {
//   if (document.documentElement.dataset.nubshoptag) {
//     new Nubshop();
//   }
// });

import * as dompack from 'dompack';
import * as mso from '@mod-mso/webdesigns/mso/mso';
import '@mod-mso/webdesigns/mso/mso.scss';

dompack.onDomReady(() => {
  new mso.MSOSite();
  new Nubshop();
});
